import React from "react";
import { MainContainer } from "components/container/MainContainer";
import phoneBackground from "assets/img/new/cellphone-background.png";
import womanBackground from "assets/img/new/woman-background.png";
import womanPhoneBackground from "assets/img/new/background-mobile.png";
import price from "assets/img/new/price.png";
import creditCard from "assets/img/new/credit-card-icon.png";
import { faAnglesRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import "./cardExplainContainer.scss";

export const CardExplainContainer = () => {
  return (
    <div className="card-explain">
      <MainContainer>
        <div className="home-text">
          <p className="f-title">
            {/* Consultas e orientações médicas{" "}
            <span className="green">ilimitadas 24h,</span> com atendimento{" "}
            <span>imediato</span> ou <span>agendado</span>,
            <span>sem custo</span> adicional! */}
            {/* Acesso a mais completa Rede de Saúde presencial do Brasil,
            com até <span className="green">80% de economia</span> em exames de imagens, laboratoriais e especialidades médicas, odontológicas, psicólogos, e muito mais! */}
            Acesso a mais completa rede de saúde presencial e online do Brasil, com até <span className="green">80% de economia</span> em, especialidades médicas, dentistas, psicólogos, nutricionistas e muito mais!
          </p>
          <p></p>

          <p className="f-title">
            {/* Tenha até <span>80% de desconto</span> na maior Rede Credenciada do
            Brasil em consultas, exames e procedimentos estéticos.
            <span>*Sem carência</span> */}
            {/* Consultas ilimitadas <span className="green">24h</span> com os renomados médicos do <span className="green">Einstein Conecta</span> */}
            Telemedicina Einstein Conecta com consultas ilimitadas, sem custo adicional <span className="green">24 horas</span> e atendimento imediato.
          </p>

          <p></p>

          <p className="f-title">
            {/* Economia de até <span className="green">35%</span>em Farmácias */}
            Economize até <span className="green">35%</span> em Farmácias presenciais ou delivery.
          </p>

          <p></p>

          <p className="f-title">
            {/* Seguros com a <span className="green">Porto Seguro</span> (Vida, acidentes pessoais e funerário) */}
            Seguros com a <span className="green">Porto Seguro</span> (Vida, acidentes pessoais, invalidez, auxílio funerário).
          </p>

          <p></p>

          <p className="f-title">
            {/* Economia de <span className="green">40%</span> na Universidade Cruzeiro do Sul */}
            Economia de até <span className="green">50%</span> na Universidade Cruzeiro do Sul presencial e EAD.
          </p>

          <p></p>

          <p className="f-title">
            {/* Sem carência */}
            Economize na saúde, educação, bem-estar e ainda concorra a <span className="green">R$5.500,00</span> todos os meses.
          </p>

          {/* <p className="f-subtitle">
            Economia na saúde a um clique de você.
          </p> */}

          <div className="sign-now-container">
            <a
              href="#pacotes"
              className="btn-sign-now"
            >
              Assine agora
              <FontAwesomeIcon
                icon={faAnglesRight}
                size={"2xs"}
                color={"#ffffff"}
                className="ps-2 pt-1"
              />
            </a>



            <div className="ms-4 d-flex">
              <div className="background-credit-card">
                <img src={creditCard} alt="Ícone Cartão de Crédito" />
              </div>
              <div className="pricing-container">
                <img src={price} alt="Preço" />
              </div>
            </div>
          </div>
        </div>
      </MainContainer>

      <div className="background-images">
        <div className="background-woman">
          <div>
            <img src={womanBackground} alt="Mulher acenando jóia" />
          </div>
        </div>

        <div className="background-phone">
          <div>
            <img src={phoneBackground} alt="Mulher acenando jóia" />
          </div>
        </div>
      </div>

      <div className="background-mobile">
        <img src={womanPhoneBackground} alt="Mulher acenando jóia" />
      </div>

      <div className="anchor">
        <a href="#pacotes">
          <FontAwesomeIcon
            icon={faAngleDown}
            size={"3x"}
            color={"#ffffff"}
            className="ps-2 pt-1"
          />
        </a>
      </div>
    </div>
  );
};

export default CardExplainContainer;
